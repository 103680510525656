import React from "react";
import FahrzeugbestellungenTable from "./FahrzeugbestellungenTable";
import AufmassTable from "./AufmassTable";
import SearchBar from "../SearchBar";

const TableContent = ({
  activeTab,
  tableData,
  filteredData,
  searchText,
  setSearchText,
  downloadingProjectId,
  downloadingERPOrder,
  downloadFile,
}) => {
  return (
    <>
      {(activeTab === "aufmass" || activeTab === "fahrzeugbestellungen") && (
        <SearchBar
          searchText={searchText}
          setSearchText={setSearchText}
          placeholder={
            activeTab === "aufmass"
              ? "Suche nach Projekt oder Kundenname"
              : "Suche nach Auto oder Erfinderperson"
          }
        />
      )}
      {activeTab === "fahrzeugbestellungen" && (
        <FahrzeugbestellungenTable
          data={tableData}
          downloadingProjectId={downloadingProjectId}
          downloadingERPOrder={downloadingERPOrder}
          downloadFile={downloadFile}
        />
      )}
      {activeTab === "aufmass" && (
        <AufmassTable
          data={filteredData}
          downloadingProjectId={downloadingProjectId}
          downloadFile={downloadFile}
        />
      )}
    </>
  );
};

export default TableContent;
