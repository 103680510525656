import React from "react";
import { Spinner } from "react-bootstrap";

const LoadingSpinner = () => (
  <div className="text-center mt-4">
    <Spinner animation="border" />
  </div>
);

export default LoadingSpinner;
