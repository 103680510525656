import React from "react";
import { createRoot } from "react-dom/client";
import { MsalProvider } from "@azure/msal-react";
import { msalInstance } from "./msalConfig";
import App from "./App";

const rootElement = document.getElementById("root");

const root = createRoot(rootElement);

root.render(
  <MsalProvider instance={msalInstance}>
    <App />
  </MsalProvider>
);
