import React from "react";
import { Table, Alert } from "react-bootstrap";
import { UilPen, UilTrash, UilArrowUp, UilArrowDown } from "@iconscout/react-unicons";

const TableComponent = ({
  data = [],
  columns = [],
  actions = {},
  isLoading = false,
  onRowClick = null,
  hasOrder = false,
  title = "",
  formatKey = (key) => key,
}) => {
  const { onEdit, onDelete, onMoveUp, onMoveDown } = actions;

  return (
    <div className="mb-5">
      {isLoading ? (
        <div>Loading...</div>
      ) : data.length === 0 ? (
        <Alert variant="warning">
          Keine Daten verfügbar für {title}. Du kannst mit der Schaltfläche 'Neu' oben ein neues erstellen.
        </Alert>
      ) : (
        <Table striped hover responsive>
          <thead>
            <tr>
              {hasOrder && <th></th>}
              {columns.map((col) => (
                <th key={col}>{formatKey(col)}</th>
              ))}
              <th className="text-center">Aktionen</th>
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => (
              <tr key={item.id} onClick={() => onRowClick && onRowClick(item)}>
                {hasOrder && (
                  <td>
                    <div className="d-flex flex-column">
                      <UilArrowUp
                        size="18"
                        style={{ cursor: "pointer", marginBottom: "4px" }}
                        onClick={(e) => {
                          e.stopPropagation();
                          onMoveUp && onMoveUp(index);
                        }}
                      />
                      <UilArrowDown
                        size="18"
                        style={{ cursor: "pointer" }}
                        onClick={(e) => {
                          e.stopPropagation();
                          onMoveDown && onMoveDown(index);
                        }}
                      />
                    </div>
                  </td>
                )}
                {columns.map((col) => (
                  <td key={`${item.id}-${col}`}>
                    {/* Special handling for "required" column */}
                    {col === "required" ? (
                      item[col] ? "Ja" : "Nein"
                    ) : (
                      item[col] !== undefined ? item[col] : "N/A"
                    )}
                  </td>
                ))}
                <td className="text-center">
                  {onEdit && (
                    <UilPen
                      size="14"
                      className="me-3"
                      style={{ cursor: "pointer" }}
                      onClick={(e) => {
                        e.stopPropagation();
                        onEdit(item);
                      }}
                    />
                  )}
                  {onDelete && (
                    <UilTrash
                      size="14"
                      style={{ cursor: "pointer", color: "red" }}
                      onClick={(e) => {
                        e.stopPropagation();
                        onDelete(item);
                      }}
                    />
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </div>
  );
};

export default TableComponent;
