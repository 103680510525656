import React from "react";
import { Alert } from "react-bootstrap";

const ErrorAlert = ({ error }) => (
  <Alert variant="danger" className="mt-4">
    {error}
  </Alert>
);

export default ErrorAlert;
