import React from 'react';

const SearchBar = ({ searchText, setSearchText, placeholder }) => {
  return (
    <div className="mb-3">
      <input
        type="text"
        placeholder={placeholder}
        className="form-control"
        value={searchText}
        onChange={(e) => setSearchText(e.target.value)}
      />
    </div>
  );
};

export default SearchBar;