import React from 'react';
import { Container, Image, Nav } from "react-bootstrap";
import logo from "../images/logo.png";

const Header = ({ activeTab, setActiveTab }) => {
  return (
    <header className="subheader">
      <Container>
        <div className="row align-items-center rounded-0">
          <div className="col-auto">
            <div className="d-flex align-items-center">
              <Image src={logo} alt="Montamo Logo" height={50} className="me-2" />
              <h1 className="m-0 text-warning" style={{ fontSize: "2rem" }}>
                Montamo
              </h1>
            </div>
          </div>
          <div className="col">
            <Nav
              variant="tabs"
              activeKey={activeTab}
              onSelect={(selectedTab) => setActiveTab(selectedTab)}
              className="justify-content-end"
            >
              <Nav.Item>
                <Nav.Link eventKey="fahrzeugbestellungen">Fahrzeugbestellungen</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="aufmass">Aufmaß</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="projektvorlagen">Projektvorlagen</Nav.Link>
              </Nav.Item>
            </Nav>
          </div>
        </div>
      </Container>
    </header>
  );
};

export default Header;