import React from 'react';
import DataTable from "react-data-table-component";
import { Spinner } from "react-bootstrap";

const AufmassTable = ({ data, downloadingProjectId, downloadFile }) => {
  const columns = [
    {
      name: "Projekt Name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Kunde Name",
      selector: (row) => row.customer_name,
      sortable: true,
    },
    {
      name: "Aufmaß herunterladen",
      cell: (row) => (
        <button
          type="button"
          className="btn btn-outline-primary py-1 px-2"
          onClick={() => downloadFile(row, 'aufmass')}
          disabled={downloadingProjectId === row.id}
          style={{ fontSize: "0.75rem" }}
        >
          {downloadingProjectId === row.id ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <Spinner as="span" animation="border" size="sm" className="me-2">
                <span className="visually-hidden">Laden...</span>
              </Spinner>
              Wird heruntergeladen...
            </div>
          ) : (
            "Aufmaß herunterladen"
          )}
        </button>
      ),
    },
  ];

  return (
    <DataTable
      columns={columns}
      data={data}
      pagination
      highlightOnHover
      pointerOnHover
      striped
    />
  );
};

export default AufmassTable;