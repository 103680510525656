import React, { useState } from "react";
import DataTable from "react-data-table-component";
import { Spinner } from "react-bootstrap";

const FahrzeugbestellungenTable = ({ data, downloadFile }) => {
  const [downloadingRFId, setDownloadingRFId] = useState(null);
  const [downloadingERPId, setDownloadingERPId] = useState(null);

  const handleDownload = async (row, type) => {
    if (type === "rf") {
      setDownloadingRFId(row.id);
    } else if (type === "erp") {
      setDownloadingERPId(row.id);
    }

    try {
      await downloadFile(row, type);
    } finally {
      if (type === "rf") {
        setDownloadingRFId(null);
      } else if (type === "erp") {
        setDownloadingERPId(null);
      }
    }
  };

  const columns = [
    {
      name: "Auto",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Inventur Person",
      selector: (row) => row.customer_name,
      sortable: true,
    },
    {
      name: "Datum",
      selector: (row) => new Date(row.created_at).toLocaleDateString("de-DE"),
      sortable: true,
    },
    {
      name: "Bestellung",
      cell: (row) => (
        <>
          <button
            type="button"
            className="btn btn-outline-primary py-1 px-2"
            onClick={() => handleDownload(row, "rf")}
            disabled={downloadingRFId === row.id}
            style={{ fontSize: "0.75rem" }}
          >
            {downloadingRFId === row.id ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100%",
                }}
              >
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  className="me-2"
                >
                  <span className="visually-hidden">Laden...</span>
                </Spinner>
                Wird heruntergeladen...
              </div>
            ) : (
              "R+F Bestellung herunterladen"
            )}
          </button>
          <button
            type="button"
            className="btn btn-outline-primary py-1 px-2"
            onClick={() => handleDownload(row, "erp")}
            disabled={downloadingERPId === row.id}
            style={{ fontSize: "0.75rem", marginLeft: "0.5rem" }}
          >
            {downloadingERPId === row.id ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100%",
                }}
              >
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  className="me-2"
                >
                  <span className="visually-hidden">Laden...</span>
                </Spinner>
                Wird heruntergeladen...
              </div>
            ) : (
              "ERP Bestellung herunterladen"
            )}
          </button>
        </>
      ),
      width: "450px",
    },
  ];

  return (
    <DataTable
      columns={columns}
      data={data}
      pagination
      highlightOnHover
      pointerOnHover
      striped
    />
  );
};

export default FahrzeugbestellungenTable;
