import React, { useState, useEffect, useCallback } from "react";
import { Container } from "react-bootstrap";
import Header from "./components/Header";
import TableContent from "./components/tables/TableContent";
import LoadingSpinner from "./components/alerts/LoadingSpinner";
import ErrorAlert from "./components/alerts/ErrorAlert";
import Projektvorlagen from "./Projektvorlagen";
import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import { loginRequest } from "./msalConfig";
import useFileDownload from "./components/useFileDownload";
import './App.css'; 

const App = () => {
  const { instance } = useMsal();
  const isAuthenticated = useIsAuthenticated();

  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [activeTab, setActiveTab] = useState("fahrzeugbestellungen");
  const [searchText, setSearchText] = useState("");

  const API_URL = process.env.REACT_APP_API_URL || 'https://default-api-url.com'; 
  const token = process.env.REACT_APP_API_TOKEN || 'default-api-token'; 


  const { downloadingProjectId, downloadingERPOrder, downloadFile } = useFileDownload(API_URL, token);

  const fetchTableData = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      // When active tab is not templates, then trigger loading of data here
      if (activeTab !== "projektvorlagen") {
        let apiUrl = `${API_URL}/projects?type=car_stocktaking_shk`;
        if (activeTab === "aufmass") {
          apiUrl = `${API_URL}/projects?type=heatpump_installation`;
        }
        const response = await fetch(apiUrl, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: "application/json",
          },
        });
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        setTableData(data);
      }
    } catch (error) {
      setError("Failed to fetch data. Please try again later.");
    } finally {
      setLoading(false);
    }
  }, [API_URL, token, activeTab]);

  useEffect(() => {
    fetchTableData();
  }, [fetchTableData, activeTab]);

  const handleTabChange = (newTab) => {
    setActiveTab(newTab);
    setSearchText("");
  };

  const filteredData = tableData.filter(
    (item) =>
      item.name.toLowerCase().includes(searchText.toLowerCase()) ||
      item.customer_name.toLowerCase().includes(searchText.toLowerCase())
  );

  if (!isAuthenticated) {
    return (
      <Container className="mt-5 text-center">
        <h1>Welcome to Montamo</h1>
        <button className="btn btn-primary" onClick={() => instance.loginRedirect(loginRequest)}>
          Login with Microsoft
        </button>
      </Container>
    );
  }

  return (
    <>
      <Header activeTab={activeTab} setActiveTab={handleTabChange} />
      <Container className="mt-5">
        {loading ? (
          <LoadingSpinner />
        ) : error ? (
          <ErrorAlert error={error} />
        ) : (
          <>
            {activeTab === "projektvorlagen" ? (
              <Projektvorlagen />
            ) : (
              <TableContent
                activeTab={activeTab}
                tableData={tableData}
                filteredData={filteredData}
                searchText={searchText}
                setSearchText={setSearchText}
                downloadingProjectId={downloadingProjectId}
                downloadingERPOrder={downloadingERPOrder}
                downloadFile={downloadFile}
              />
            )}
          </>
        )}
      </Container>
    </>
  );
};

export default App;