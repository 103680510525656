import { useState } from "react";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";

const useFileDownload = (API_URL, token) => {
  const [downloadingProjectId, setDownloadingProjectId] = useState(null);
  const [downloadingERPOrder, setDownloadingERPOrder] = useState(null);

  const downloadFile = async (project, type) => {
    setDownloadingProjectId(project.id);
    try {
      const endpoint = `${API_URL}/used-items?fullDetails=true&project_id=${project.id}`;
      const response = await fetch(endpoint, {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
        },
      });

      if (!response.ok) {
        throw new Error(`Failed to fetch ${type === "rf" ? "R+F Bestellung" : "Aufmaß"} data`);
      }

      const data = await response.json();
      if (!data || data.length === 0) {
        alert(`No ${type === "rf" ? "R+F Bestellung" : "Aufmaß"} data found for project: ${project.name}`);
        return;
      }

      let processedData;
      if (type === "rf") {
        
        setDownloadingProjectId(true);
        processedData = data
          .filter(item => item.construction_project_id === project.id && item.quantity !== null)
          .map(({ quantity, target_quantity, item }) => {
            const delta = (Number(target_quantity) || 0) - (Number(quantity) || 0);
            return {
              vendor_item_no: item.vendor_item_no || "",
              delta: delta || 0,
              unit: item.base_unit || "",
            };
          })
          .filter((item) => item && item.delta > 0);

        // Generate CSV for R+F Bestellung
        const csvContent = processedData
        .map((row) => Object.values(row).join(";"))
        .join("\n");

        const dataBlob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
        saveAs(dataBlob, `RF_Bestellung_${project.name}.csv`);
      } else if (type === "erp") {
        setDownloadingERPOrder(true);
        processedData = data
          .filter(item => item.construction_project_id === project.id && item.quantity !== null)
          .map(({ quantity, target_quantity, item }) => {
            const delta = (Number(target_quantity) || 0) - (Number(quantity) || 0);
            return {
              type: "Artikel",
              erp_item_no: item.erp_item_no || "",
              order_status: "angenommen wie bestellt",
              item_reference: item.vendor_item_no || "",
              item_description: item.name || "",
              warehouse: project.name || "",
              quantity: delta || 0,
              unit: item.base_unit === "STK" ? "ST" : item.base_unit || "",
            };
          })
          .filter(item => item.quantity > 0);
        // Generate XLSX
        const ws = XLSX.utils.json_to_sheet(processedData);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, `Fahrzeugbestellung ${project.name}`);
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const dataBlob = new Blob([excelBuffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
        saveAs(dataBlob, `ERP_Fahreugbestellung_${project.name}.xlsx`);
      } else {
        // Handle Aufmass download
        processedData = data.map(used_item => ({
          erp_item_no: used_item.item.erp_item_no,
          vendor_item_no: used_item.item.vendor_item_no || "",
          name: used_item.item.name || "",
          quantity: used_item.quantity || 0,
        }));
        const ws = XLSX.utils.json_to_sheet(processedData);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Aufmass");
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const dataBlob = new Blob([excelBuffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
        saveAs(dataBlob, `Aufmass_${project.name}.xlsx`);
      }
    } catch (error) {
      console.error(`Error downloading file:`, error);
      alert(`Failed to download file. Please try again later.`);
    } finally {
      setDownloadingProjectId(null);
    }
  };

  return { downloadingProjectId, downloadingERPOrder, downloadFile };
};

export default useFileDownload;
