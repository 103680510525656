import { PublicClientApplication } from "@azure/msal-browser";

const getRedirectUri = () => {
  const hostname = window.location.hostname;
  const protocol = window.location.protocol;
  
  if (process.env.REACT_APP_REDIRECT_URI) {
    return process.env.REACT_APP_REDIRECT_URI; // Use the redirect URI from the environment if available
  }

  if (hostname === 'localhost' || hostname === '127.0.0.1') {
    return 'http://localhost:3000'; // Development
  } else if (hostname === 'dev-webapp.montamo.engineer') {
    return 'https://dev-webapp.montamo.engineer'; // Cloudflare dev environment
  } else if (hostname === 'montamo.engineer') {
    return 'https://montamo.engineer'; // Production URL
  } else if (hostname.endsWith('.pages.dev')) {
    return `${protocol}//${hostname}`; // Cloudflare Pages domain
  } else {
    // Fallback to current origin if unexpected hostname
    console.warn('Unexpected hostname:', hostname);
    return window.location.origin; // Using window.location.origin for complete URI
  }
};

// MSAL config object
export const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_AZURE_CLIENT_ID, // Ensure this is correct
    authority: `https://login.microsoftonline.com/${process.env.REACT_APP_AZURE_TENANT_ID}`, // Ensure tenant ID is set correctly
    redirectUri: getRedirectUri(), // Set redirect URI dynamically
  },
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: false,
  },
};

// Create MSAL instance
export const msalInstance = new PublicClientApplication(msalConfig);

// Example login request
export const loginRequest = {
  scopes: ['User.Read'], // Scopes for login
};
