import React from "react";
import { Modal, Button, Form } from "react-bootstrap";
import Autosuggest from "react-autosuggest";

const ModalComponent = ({
  showModal,
  handleClose,
  handleSubmit,
  modalData,
  handleInputChange,
  currentEntityType,
  filteredItems,
  setFilteredItems,
  getSuggestions,
  onSuggestionSelected,
  inputProps,
}) => {
  return (
    <Modal show={showModal} onHide={handleClose} size="lg">
      <Form onSubmit={handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title>
            {currentEntityType === "template_project"
              ? "Projektvorlage"
              : currentEntityType === "template_trade"
              ? "Gewerk"
              : currentEntityType === "template_installation_step"
              ? "Installationsschritt"
              : "Benötigter Artikel"}{" "}
            {modalData.id ? "bearbeiten" : "hinzufügen"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {currentEntityType !== "template_used_item" && (
            <>
              <Form.Group className="mb-3" controlId="formName">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  value={modalData.name || ""}
                  onChange={handleInputChange}
                  placeholder="Name eingeben"
                  required
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formDescription">
                <Form.Label>Beschreibung</Form.Label>
                <Form.Control
                  as="textarea"
                  name="description"
                  value={modalData.description || ""}
                  onChange={handleInputChange}
                  rows={3}
                  placeholder="Beschreibung eingeben"
                />
              </Form.Group>
            </>
          )}

          {currentEntityType === "template_project" && (
            <Form.Group className="mb-3" controlId="formType">
              <Form.Label>Typ</Form.Label>
              <Form.Control
                as="select"
                name="type"
                value={modalData.type || ""}
                onChange={handleInputChange}
                required
              >
                <option value="">Projekttyp auswählen</option>
                <option value="heatpump_installation">
                  Installation Wärmepumpe
                </option>
                <option value="car_stocktaking_shk">
                  Fahrzeuginventur SHK
                </option>
              </Form.Control>
            </Form.Group>
          )}

          {currentEntityType === "template_used_item" && (
            <>
              <Form.Group className="mb-3" controlId="formSearchItem">
                <Form.Label>Artikel suchen</Form.Label>
                <Autosuggest
                  suggestions={filteredItems}
                  onSuggestionsFetchRequested={({ value }) =>
                    setFilteredItems(getSuggestions(value))
                  }
                  onSuggestionsClearRequested={() => setFilteredItems([])}
                  getSuggestionValue={(suggestion) => suggestion.name}
                  renderSuggestion={(suggestion) => <div>{suggestion.name}</div>}
                  onSuggestionSelected={onSuggestionSelected}
                  inputProps={{
                    ...inputProps,
                    className: "form-control",
                  }}
                  theme={{
                    container: "autosuggest-container",
                    suggestionsList: "list-group",
                    suggestion: "list-group-item",
                    suggestionHighlighted: "list-group-item active",
                  }}
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formRequired">
                <div className="d-flex align-items-center">
                  <Form.Check
                    type="checkbox"
                    name="required"
                    className="me-2"
                    checked={modalData.required || false}
                    onChange={handleInputChange}
                  />
                  <Form.Label className="mb-0">Erforderlich</Form.Label>
                </div>
              </Form.Group>

              <Form.Group className="mb-3" controlId="formTargetQuantity">
                <Form.Label>Soll Menge</Form.Label>
                <Form.Control
                  type="number"
                  name="target_quantity"
                  value={modalData.target_quantity || ""}
                  onChange={handleInputChange}
                  placeholder="Soll Menge eingeben"
                />
              </Form.Group>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Schließen
          </Button>
          <Button variant="primary" type="submit">
            Änderungen speichern
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default ModalComponent;
