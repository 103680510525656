import { useState, useEffect, useCallback, useMemo } from "react";
import { Spinner, Alert} from "react-bootstrap";
import {
  UilRedo,
  UilArrowLeft,
  UilPlusCircle,
} from "@iconscout/react-unicons";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TableComponent from "./components/tables/TableComponent";
import ModalComponent from "./components/tables/ModalComponent";

const API_URL = process.env.REACT_APP_API_URL;
const token = process.env.REACT_APP_API_TOKEN;

// eslint-disable-next-line 
const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

const Projektvorlagen = () => {
  const [formData, setFormData] = useState({
    template_project: [],
    template_trade: [],
    template_installation_step: [],
    template_used_item: [],
    items: [],
  });
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [selectedProject, setSelectedProject] = useState(null);
  const [selectedTrade, setSelectedTrade] = useState(null);
  const [selectedInstallationStep, setSelectedInstallationStep] =
    useState(null);
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState({});
  const [currentEntityType, setCurrentEntityType] =
    useState("template_project");
  const [, setActionType] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [, setFilteredItems] = useState([]);
  const [usedItemsOrder, setUsedItemsOrder] = useState([]);
  const [installationStepsOrder, setInstallationStepsOrder] = useState([]);
  const [, setIsReorderingUsedItems] = useState(false);
  const [, setIsLoadingUsedItems] = useState(false);
  const [tradesOrder, setTradesOrder] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchWithRetry = useCallback(async (url, options, retries = 3) => {
    try {
      const response = await fetch(url, options);
      if (response.status === 429) {
        await delay(20000);
        return fetchWithRetry(url, options, retries - 1);
      }
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      return response.json();
    } catch (error) {
      if (retries > 0) {
        await delay(1000);
        return fetchWithRetry(url, options, retries - 1);
      }
      throw error;
    }
  }, []);

  useEffect(() => {
    const filtered = formData.items.filter((item) =>
      item.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredItems(filtered);
  }, [searchTerm, formData.items]);

  const fetchData = useCallback(
    async (endpoint, params = "", showLoading = true) => {
      if (showLoading) setLoading(true);
      setError(null);
      try {
        const response = await fetchWithRetry(
          `${API_URL}/${endpoint}${params}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              Accept: "application/json",
            },
          }
        );
        return response;
      } catch (error) {
        console.error("Error fetching data:", error);
        setError("Failed to fetch data. Please try again later.");
        return [];
      } finally {
        if (showLoading) setLoading(false);
      }
    },
    [fetchWithRetry] 
  );
  
  const loadTrade = useCallback(
    async (projectId) => {
      if (!projectId) return;

      try {
        const trades = await fetchData(
          `template_trade?template_project_id=${projectId}`
        );

        const filteredTrades = Array.isArray(trades)
          ? trades.filter((trade) => trade.template_project_id === projectId)
          : [];

        const sortedTrades = filteredTrades.sort(
          (a, b) => a.display_order - b.display_order
        );

        setFormData((prevData) => ({
          ...prevData,
          template_trade: sortedTrades,
        }));

        setTradesOrder(sortedTrades);

        return sortedTrades;
      } catch (error) {
        console.error("Error loading trades:", error);
        toast.error("Fehler beim Laden der Gewerke.");
        return [];
      }
    },
    [fetchData]
  );

  const loadProjects = useCallback(async () => {
    const projects = await fetchData("template_project");
    setFormData((prevData) => ({
      ...prevData,
      template_project: Array.isArray(projects) ? projects : [],
    }));
  }, [fetchData]);

  const loadInstallationStep = useCallback(
    async (tradeId) => {
      if (!tradeId) return;

      try {
        const steps = await fetchData(
          `template_installation_step?template_project_trade_id=${tradeId}`
        );

        const sortedSteps = Array.isArray(steps)
          ? steps.sort((a, b) => a.display_order - b.display_order)
          : [];

        setInstallationStepsOrder(sortedSteps);
        setFormData((prevData) => ({
          ...prevData,
          template_installation_step: sortedSteps,
        }));
      } catch (error) {
        console.error("Error fetching installation steps:", error);
      }
    },
    [fetchData]
  );

  const loadUsedItems = useCallback(
    async (stepId) => {
      if (!stepId) return;
  
      setIsLoadingUsedItems(true);
      try {
        const items = await fetchData(
          `template_used_item?template_project_installation_step_id=${stepId}`
        );
  
        if (Array.isArray(items)) {
          const sortedItems = items.sort((a, b) => a.display_order - b.display_order);
          setUsedItemsOrder(sortedItems);
          setFormData((prevData) => ({
            ...prevData,
            template_used_item: sortedItems,
          }));
        } else {
          setUsedItemsOrder([]);
          setFormData((prevData) => ({
            ...prevData,
            template_used_item: [],
          }));
        }
      } catch (error) {
        console.error("Error loading used items:", error);
        setUsedItemsOrder([]);
        setFormData((prevData) => ({
          ...prevData,
          template_used_item: [],
        }));
      } finally {
        setIsLoadingUsedItems(false);
      }
    },
    [fetchData]
  );

  const loadItems = useCallback(async () => {
    const items = await fetchData("items", "", false);
    setFormData((prevData) => ({
      ...prevData,
      items: Array.isArray(items) ? items : [],
    }));
  }, [fetchData]);

  useEffect(() => {
    loadProjects();
    loadItems();
  }, [loadProjects, loadItems]);

  const mergedUsedItems = useMemo(() => {
    if (!Array.isArray(usedItemsOrder) || !Array.isArray(formData.items)) {
      return [];
    }
  
    return usedItemsOrder.map((usedItem) => {
      const matchingItem = formData.items.find(
        (item) => item.id === usedItem.item_id
      );
  
      return {
        ...usedItem,
        name: matchingItem ? matchingItem.name : "Unknown",
        quantity: usedItem.quantity || "N/A",
        required: usedItem.required || false,
        target_quantity: usedItem.target_quantity || "N/A",
      };
    });
  }, [usedItemsOrder, formData.items]);


  const handleProjectClick = useCallback(
    (project) => {
      setSelectedProject(project);
      setSelectedTrade(null);
      setSelectedInstallationStep(null);
      loadTrade(project.id);
    },
    [loadTrade]
  );

  const handleTradeClick = useCallback(
    (trade) => {
      setSelectedTrade(trade);
      setSelectedInstallationStep(null);
      loadInstallationStep(trade.id);
    },
    [loadInstallationStep]
  );

  const handleInstallationStepClick = useCallback(
    (step) => {
      setSelectedInstallationStep(step);
      loadUsedItems(step.id);
    },
    [loadUsedItems]
  );

  const handleBack = useCallback(() => {
    if (selectedInstallationStep) {
      setSelectedInstallationStep(null);
    } else if (selectedTrade) {
      setSelectedTrade(null);
      loadTrade(selectedProject?.id);
    } else if (selectedProject) {
      setSelectedProject(null);
      setFormData((prevData) => ({ ...prevData, template_trade: [] }));
      loadProjects();
    }
  }, [
    selectedInstallationStep,
    selectedTrade,
    selectedProject,
    loadTrade,
    loadProjects,
  ]);

  const resetStates = () => {
    setSelectedItem(null);
    setModalData({});
    setSearchTerm("");
    setFilteredItems([]);
  };

  const [, setSelectedItem] = useState(null);
  useEffect(() => {
    const filtered = formData.items.filter((item) =>
      item.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredItems(filtered);
  }, [searchTerm, formData.items]);

  const handleItemSelect = useCallback((item) => {
    setSelectedItem(item);
    setModalData((prevData) => ({
      ...prevData,
      item_id: item.id,
    }));
    setSearchTerm(item.name);
  }, []);

  const getSuggestions = (value) => {
    const inputValue = value.trim().toLowerCase();
    return inputValue.length === 0
      ? []
      : formData.items.filter((item) =>
          item.name.toLowerCase().includes(inputValue)
        );
  };
  // eslint-disable-next-line 
  const renderSuggestion = (suggestion) => <div>{suggestion.name}</div>;
  const onSearchChange = (event, { newValue }) => {
    setSearchTerm(newValue);
  };
// eslint-disable-next-line 
  const inputProps = {
    placeholder: "Nach einem Artikel suchen...",
    value: searchTerm,
    onChange: onSearchChange,
  };
  // eslint-disable-next-line 
  const getSuggestionValue = (suggestion) => suggestion.name;
  const onSuggestionSelected = (event, { suggestion }) => {
    handleItemSelect(suggestion);
  };

  const handleCreate = useCallback(
    (entityType = "template_project") => {
      const prefixedEntityType = entityType.startsWith("template_")
        ? entityType
        : `template_${entityType}`;
      setCurrentEntityType(prefixedEntityType);

      let initialData = {};
      switch (prefixedEntityType) {
        case "template_project":
          initialData = {
            name: "",
            customer_name: "",
            address: "",
            phone_no: "",
            notes: "",
            type: "Type A",
          };
          break;
        case "template_trade":
          initialData = {
            name: "",
            description: "",
            template_project_id: selectedProject?.id ?? null,
            display_order: 0,
          };
          break;
        case "template_installation_step":
          initialData = {
            name: "",
            description: "",
            template_project_trade_id: selectedTrade?.id ?? null,
          };
          break;
        case "template_used_item":
          initialData = {
            template_project_installation_step_id:
              selectedInstallationStep?.id ?? null,
            item_id: "",
            quantity: 0,
            required: false,
            target_quantity: 0,
          };
          break;
        default:
          console.warn(`Unhandled entity type: ${prefixedEntityType}`);
          initialData = { name: "" };
      }
      resetStates();
      setModalData(initialData);
      setShowModal(true);
      setActionType("create");
    },
    [selectedProject, selectedTrade, selectedInstallationStep, setActionType]
  );

  const handleDelete = useCallback(
    async (id, entityType, item) => {
      const entityKey = {
        project: "template_project",
        trade: "template_trade",
        installation_step: "template_installation_step",
        used_item: "template_used_item",
      }[entityType.toLowerCase()];
  
      if (!entityKey) {
        console.error(`Invalid entity type for deletion: ${entityType}`);
        return;
      }
  
      const confirmDelete = window.confirm(
        `Sind Sie sicher, dass Sie ${item.name} löschen möchten?`
      );
      if (!confirmDelete) return;
  
      try {
        const response = await fetch(`${API_URL}/${entityKey}/${id}`, {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
  
        if (!response.ok) {
          throw new Error(`Fehler beim Löschen von ${entityType} mit der ID ${id}`);
        }
  
        toast.error(`${item.name} wurde erfolgreich gelöscht.`);
  
        setTimeout(async () => {
          if (entityType === "project") {
            await loadProjects();
          } else if (entityType === "trade") {
            if (selectedProject) {
              await loadTrade(selectedProject.id);
            }
          } else if (entityType === "installation_step") {
            if (selectedTrade) {
              await loadInstallationStep(selectedTrade.id);
            }
          } else if (entityType === "used_item") {
            if (selectedInstallationStep) {
              await loadUsedItems(selectedInstallationStep.id);
            }
          }
        }, 2000);
      } catch (error) {
        console.error(`Fehler beim Löschen von ${entityType}:`, error);
        toast.error(`Fehler beim Löschen von ${entityType}. Bitte versuchen Sie es später erneut.`);
      }
    },
    [
      loadProjects,
      loadTrade,
      loadInstallationStep,
      loadUsedItems,
      selectedProject,
      selectedTrade,
      selectedInstallationStep,
    ]
  );
  
  const handleEdit = useCallback(
    (item, entityType) => {
      const prefixedEntityType = entityType.startsWith("template_")
        ? entityType
        : `template_${entityType}`;
      setCurrentEntityType(prefixedEntityType);
  
      setModalData(item);
  
      if (prefixedEntityType === "template_used_item") {
        const selectedItem = formData.items.find((i) => i.id === item.item_id);
        if (selectedItem) {
          setSelectedItem(selectedItem);
          setSearchTerm(selectedItem.name);
        }
      }
  
      setShowModal(true);
      setActionType("Edit");
      setSelectedInstallationStep(null);
    },
    [formData.items, setActionType]
  );

  const formatKey = useMemo(() => (key) => {
    const keyMapping = {
      template_project: "Projektvorlage",
      template_trade: "Gewerk",
      template_installation_step: "Installationsschritt",
      template_used_item: "Benötigten Artikel",
      Project: "Projektvorlagen",
      "Installation Step": "Installationsschritte",
      "Used Item": "Benötigte Artikel",
      Trade: "Gewerke",
      name: "Name",
      description: "Beschreibung",
      type: "Typ",
    };
  
    return (
      keyMapping[key] ||
      key
        .split("_")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ")
    );
  }, []);

  useEffect(() => {
    if (successMessage) {
      const timer = setTimeout(() => setSuccessMessage(null), 4000);
      return () => clearTimeout(timer);
    }
  }, [successMessage]);

  const handleModalSubmit = useCallback(
    async (event) => {
      event.preventDefault();

      if (
        currentEntityType !== "template_used_item" &&
        (!modalData.name || modalData.name.trim() === "")
      ) {
        setError("Name is required");
        return;
      }

      let url = `${API_URL}/${currentEntityType}`;
      let method = "POST";
      if (modalData.id) {
        url += `/${modalData.id}`;
        method = "PUT";
      }

      const modalDataToSubmit = {
        ...(currentEntityType === "template_project" && {
          name: modalData.name || "",
          description: modalData.description || "",
          type: modalData.type || "Type A",
        }),
        ...(currentEntityType === "template_trade" && {
          name: modalData.name || "",
          description: modalData.description || "",
          template_project_id: selectedProject?.id || null,
          display_order: modalData.display_order || 0,
        }),
        ...(currentEntityType === "template_installation_step" && {
          name: modalData.name || "",
          description: modalData.description || "",
          template_project_trade_id: selectedTrade?.id || null,
        }),
        ...(currentEntityType === "template_used_item" && {
          template_project_installation_step_id:
            selectedInstallationStep?.id || null,
          item_id: modalData.item_id || "",
          quantity: modalData.quantity || 0,
          required: modalData.required || false,
          target_quantity: modalData.target_quantity || 0,
        }),
      };

      try {
        const response = await fetch(url, {
          method,
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(modalDataToSubmit),
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const newItem = await response.json();
        setFormData((prevData) => {
          const updatedArray = modalData.id
            ? prevData[currentEntityType].map((item) =>
                item.id === modalData.id ? newItem : item
              )
            : [...prevData[currentEntityType], newItem];
          return { ...prevData, [currentEntityType]: updatedArray };
        });

        toast.success(
          `${newItem.name || "Eintrag"} wurde erfolgreich ${
            modalData.id ? "aktualisiert" : "erstellt"
          }.`
        );
        

        setShowModal(false);
        setTimeout(() => {
          if (currentEntityType === "template_project") {
            loadProjects();
          } else if (currentEntityType === "template_trade") {
            loadTrade(selectedProject?.id);
          } else if (currentEntityType === "template_installation_step") {
            loadInstallationStep(selectedTrade?.id);
          } else if (currentEntityType === "template_used_item") {
            loadUsedItems(selectedInstallationStep?.id);
          }
        }, 2000);
      } catch (error) {
        console.error(error);
        toast.error(
          `Fehler beim ${
            modalData.id ? "Aktualisieren" : "Erstellen"
          } von ${currentEntityType}.`
        );
      }
    },
    [
      currentEntityType,
      modalData,
      selectedProject,
      selectedTrade,
      selectedInstallationStep,
      loadProjects,
      loadTrade,
      loadInstallationStep,
      loadUsedItems,
    ]
  );
  
// eslint-disable-next-line
  const handleMoveInstallationStep = (index, direction) => {
    handleMoveItem(
      index,
      direction,
      "template_installation_step",
      installationStepsOrder,
      setInstallationStepsOrder,
      () => loadInstallationStep(selectedTrade?.id)
    );
  };

// eslint-disable-next-line
  const handleMoveUsedItem = (index, direction) => {
    handleMoveItem(
      index,
      direction,
      "used_item",
      usedItemsOrder,
      setUsedItemsOrder,
      () => loadUsedItems(selectedInstallationStep?.id)
    );
  };
  

  const moveItem = useCallback(async (list, index, direction) => {
    if (!Array.isArray(list)) {
      console.error("Expected list to be an array, but got:", list);
      return { updatedList: [], newOrder: [] };
    }

    if (
      (direction === "up" && index === 0) ||
      (direction === "down" && index === list.length - 1)
    ) {
      return { updatedList: list, newOrder: list.map((item) => item.id) };
    }

    const newList = [...list];
    const [removed] = newList.splice(index, 1);
    newList.splice(direction === "up" ? index - 1 : index + 1, 0, removed);

    const updatedList = newList.map((item, idx) => ({
      ...item,
      display_order: idx + 1,
    }));

    const newOrder = updatedList.map((item) => item.id);

    return { updatedList, newOrder };
  }, []);

  const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));

  const handleMoveItem = useCallback(
    async (index, direction, entityType, list, setList, loadFunction) => {
      if (!Array.isArray(list)) {
        console.error("Expected list to be an array in handleMoveItem:", list);
        return;
      }
  
      const { updatedList } = await moveItem(list, index, direction);
  
      setList(updatedList);
      setFormData((prevData) => ({
        ...prevData,
        [entityType]: updatedList,
      }));
  
      const updateItemWithRetry = async (item, idx, retries = 3, baseDelay = 1000) => {
        const requestBody = {
          display_order: idx + 1,
          ...(entityType === "template_trade" && {
            name: item.name,
            description: item.description,
            template_project_id: item.template_project_id,
          }),
          ...(entityType === "template_installation_step" && {
            name: item.name,
            description: item.description,
            template_project_trade_id: item.template_project_trade_id,
          }),
          ...(entityType === "template_used_item" && {
            template_project_installation_step_id: item.template_project_installation_step_id,
            item_id: item.item_id,
            required: item.required,
            target_quantity: item.target_quantity,
            quantity: item.quantity,
          }),
        };
  
        try {
          const response = await fetch(`${API_URL}/${entityType}/${item.id}`, {
            method: "PUT",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify(requestBody),
          });
  
          if (response.status === 429 && retries > 0) {
            const delayTime = baseDelay * Math.pow(2, 3 - retries);
            console.log(`Rate limited. Retrying in ${delayTime}ms...`);
            await delay(delayTime);
            return updateItemWithRetry(item, idx, retries - 1, baseDelay);
          }
  
          if (!response.ok) {
            throw new Error(`Failed to update ${entityType} item ${item.id}`);
          }
  
          return await response.json();
        } catch (error) {
          if (retries > 0) {
            const delayTime = baseDelay * Math.pow(2, 3 - retries);
            console.log(`Error occurred. Retrying in ${delayTime}ms...`);
            await delay(delayTime);
            return updateItemWithRetry(item, idx, retries - 1, baseDelay);
          }
          throw error;
        }
      };
  
      try {
        const updatePromises = updatedList.map((item, idx) => updateItemWithRetry(item, idx));
        await Promise.all(updatePromises);
  
        toast.success(`Reihenfolge der ${formatKey(entityType)} erfolgreich aktualisiert.`);
      } catch (error) {
        console.error(`Error updating ${entityType} order:`, error);
        toast.error(`Fehler beim Aktualisieren der Reihenfolge: ${error.message}`);
        const originalOrder = await loadFunction();
        setList(originalOrder);
        setFormData((prevData) => ({
          ...prevData,
          [entityType]: originalOrder,
        }));
      }
    },
    // eslint-disable-next-line
    [moveItem, setFormData, API_URL, token, formatKey]
  );

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;

    setModalData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const filteredItems = useMemo(() => {
    return formData.items.filter((item) =>
      item.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }, [searchTerm, formData.items]);

  useEffect(() => {
    if (selectedInstallationStep) {
      loadUsedItems(selectedInstallationStep.id);
    }
  }, [selectedInstallationStep, loadUsedItems]);

  useEffect(() => {
    setIsReorderingUsedItems(false);
  }, []);

  const renderModal = () => {
    return (
      <ModalComponent
        showModal={showModal}                           
        handleClose={() => setShowModal(false)}         
        handleSubmit={handleModalSubmit}                
        modalData={modalData}                           
        handleInputChange={handleInputChange}          
        currentEntityType={currentEntityType}           
        filteredItems={filteredItems}                   
        setFilteredItems={setFilteredItems}             
        getSuggestions={getSuggestions}               
        onSuggestionSelected={onSuggestionSelected}    
        inputProps={{
          placeholder: "Nach einem Artikel suchen...",  
          value: searchTerm,                            
          onChange: onSearchChange,                    
        }}
      />
    );
  };
  

  const renderTable = useCallback(
    (data, title, handleClick, handleReload, handleCreate, parentItem = null) => {
      const dynamicTitle = parentItem
        ? `${formatKey(title)} für ${parentItem.name}`
        : formatKey(title);
  
      const getListToRender = () => {
        if (title === "Used Item") return mergedUsedItems; 
        if (title === "Trade") return tradesOrder;
        if (title === "Installation Step") return installationStepsOrder;
        return data;
      };
  
      const listToRender = getListToRender();
      const getColumnsToRender = () => {
        if (title === "Used Item") {
          return ["name", "required", "target_quantity"];
        }
        return ["name", "description"];
      };
  
      const columnsToRender = getColumnsToRender();
  
      const actions = {
        onEdit: (item) => handleEdit(item, title.toLowerCase().replace(" ", "_")),
        onDelete: (item) =>
          handleDelete(item.id, title.toLowerCase().replace(" ", "_"), item),
        onMoveUp: (index) => {
          if (title === "Trade") {
            handleMoveItem(
              index,
              "up",
              "template_trade",
              tradesOrder,
              setTradesOrder,
              () => loadTrade(selectedProject?.id)
            );
          } else if (title === "Installation Step") {
            handleMoveItem(
              index,
              "up",
              "template_installation_step",
              installationStepsOrder,
              setInstallationStepsOrder,
              () => loadInstallationStep(selectedTrade?.id)
            );
          } else if (title === "Used Item") {
            handleMoveItem(
              index,
              "up",
              "template_used_item",
              usedItemsOrder,
              setUsedItemsOrder,
              () => loadUsedItems(selectedInstallationStep?.id)
            );
          }
        },
        onMoveDown: (index) => {
          if (title === "Trade") {
            handleMoveItem(
              index,
              "down",
              "template_trade",
              tradesOrder,
              setTradesOrder,
              () => loadTrade(selectedProject?.id)
            );
          } else if (title === "Installation Step") {
            handleMoveItem(
              index,
              "down",
              "template_installation_step",
              installationStepsOrder,
              setInstallationStepsOrder,
              () => loadInstallationStep(selectedTrade?.id)
            );
          } else if (title === "Used Item") {
            handleMoveItem(
              index,
              "down",
              "template_used_item",
              usedItemsOrder,
              setUsedItemsOrder,
              () => loadUsedItems(selectedInstallationStep?.id)
            );
          }
        },
      };
  
      return (
        <div className="mb-5">
          <div className="d-flex justify-content-between align-items-center mb-3">
            {parentItem && (
              <UilArrowLeft
                size="32"
                style={{ cursor: "pointer" }}
                onClick={handleBack}
                title="Back"
              />
            )}
            <h2 className="mx-auto text-center">{dynamicTitle}</h2>
            <div className="d-flex">
              <UilRedo
                size="24"
                style={{ cursor: "pointer" }}
                onClick={handleReload}
                title="Reload"
              />
              <UilPlusCircle
                size="24"
                style={{ cursor: "pointer", marginLeft: "8px" }}
                onClick={() =>
                  handleCreate(`template_${title.toLowerCase().replace(" ", "_")}`)
                }
                title="New"
              />
            </div>
          </div>
  
          <TableComponent
            data={listToRender}
            columns={columnsToRender}
            actions={actions}
            isLoading={loading}
            formatKey={(col) => {
              if (title === "Used Item") {
                if (col === "name") return "Name";
                if (col === "required") return "Erforderlich";
                if (col === "target_quantity") return "Soll Menge";
              }
              if (col === "name") return "Name";
              if (col === "description") return "Beschreibung";
              return formatKey(col);
            }}
            onRowClick={handleClick}
            hasOrder={listToRender.length > 1 && title !== "Project"}
            title={title}
          />
        </div>
      );
    },
    [
      formatKey,
      handleBack,
      handleDelete,
      handleEdit,
      handleMoveItem,
      tradesOrder,
      installationStepsOrder,
      usedItemsOrder,
      mergedUsedItems, 
      setTradesOrder,
      setInstallationStepsOrder,
      setUsedItemsOrder,
      loading,
      selectedProject,
      selectedTrade,
      selectedInstallationStep,
      loadTrade,
      loadInstallationStep,
      loadUsedItems,
    ]
  );

  if (loading) return <Spinner animation="border" />;
  if (error) return <Alert variant="danger">{error}</Alert>;

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col">
          <ToastContainer />

          {renderModal()}

          {!selectedProject &&
            renderTable(
              formData.template_project,
              "Project",
              handleProjectClick,
              loadProjects,
              handleCreate
            )}

          {selectedProject &&
            !selectedTrade &&
            renderTable(
              formData.template_trade,
              "Trade",
              handleTradeClick,
              () => loadTrade(selectedProject.id),
              handleCreate,
              selectedProject
            )}

          {selectedTrade &&
            !selectedInstallationStep &&
            renderTable(
              formData.template_installation_step,
              "Installation Step",
              handleInstallationStepClick,
              () => loadInstallationStep(selectedTrade.id),
              handleCreate,
              selectedTrade
            )}

          {selectedInstallationStep &&
            renderTable(
              mergedUsedItems,
              "Used Item",
              null,
              () => loadUsedItems(selectedInstallationStep.id),
              handleCreate,
              selectedInstallationStep
            )}
        </div>
      </div>
    </div>
  );
};

export default Projektvorlagen;
